import { Spin } from "antd";
import React from "react";
import styles from "./Loading.module.scss";

interface LoadingProps extends React.HTMLProps<HTMLDivElement> {}

export const Loading: React.FC<LoadingProps> = ({ style }) => {
  return (
    <div className={styles.content} style={style}>
      <Spin />
    </div>
  );
};
