import { StyleSheet, View } from "@react-pdf/renderer";
import { FC } from "react";
import { EventPrintResponse } from "../../../modules/home/pages/home/Home.functions";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

interface ItemsEventsProps {
  events: EventPrintResponse[];
}

const ItemsEvents: FC<ItemsEventsProps> = ({ events }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow items={events} />
  </View>
);

export default ItemsEvents;
