import React, { ComponentType, ReactNode } from "react";

class AppRoute {
  public path?: string = "/not-found";
  public name?: string = "";
  public icon?: any = undefined;
  public routes?: AppRoute[] = [];
  public component?: ComponentType | ReactNode | any = {};
  public notUseMainLayout?: boolean = false;
  public hideInMenu?: boolean = false;
  public permission?: string[] = ["admin-system"];
  public notPermission?: boolean = false;

  constructor(appRoute: AppRoute) {
    Object.assign(this, appRoute);
  }
}

export { AppRoute };
