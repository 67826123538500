import { DocumentNode, gql } from "@apollo/client";

function getEventsForPrintQuery(): DocumentNode {
  return gql`
    query EventsByGroupAndDate($request: GetEventsByPrintRequestInput!) {
      printEvents(request: $request) {
        data {
          administration
          name
          date
          warning
          types {
            type
            order
            events {
              date
              hour
              local
              people
            }
          }
        }
      }
    }
  `;
}

function getEventGroupsQuery(): DocumentNode {
  return gql`
    query {
      allPublished {
        success
        message
        data {
          id
          administration
          name
          date
          actual
        }
      }
    }
  `;
}

export { getEventGroupsQuery, getEventsForPrintQuery };

