import { MdEventNote } from "react-icons/md";
import { Navigate, useRoutes } from "react-router-dom";
import { Layout } from "./components/layout/Layout";
import { AppRoute } from "./models/appRoute.model";
import { Home } from "./modules/home/pages/home/Home";

function App() {
  let routes: AppRoute[] = [
    {
      icon: <MdEventNote />,
      name: "Lista de batismos e diversos",
      path: "/home",
      component: <Home />,
    },
    new AppRoute({
      path: "*",
      component: <Navigate to="/home" />,
    }),
  ];

  return useRoutes(
    routes.map((_: AppRoute) => ({
      path: _.path,
      element: <Layout routes={routes}>{_.component}</Layout>,
    }))
  );
}

export default App;
