import { Layout as LayoutAntd, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { useMain } from "../../contexts/main";
import { AppRoute } from "../../models/appRoute.model";
import { Breadcrumb } from "../breadcrumb";
import { Loading } from "../loadings/loading/Loading";
import { Header } from "./header/Header";
import styles from "./Layout.module.scss";

interface LayoutProps {
  children: any;
  routes: AppRoute[];
}

export const Layout = (props: LayoutProps) => {
  const { routes, children } = props;
  const { titlePage, loading, breadcrumbsItens } = useMain();

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <LayoutAntd className={styles["layout"]}>
        <Header routes={routes} />
        <Content style={{ padding: "8px" }}>
          <Breadcrumb items={breadcrumbsItens} />
          <LayoutAntd
            style={{
              padding: "8px 0",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}>
            <Content style={{ padding: "0 8px", minHeight: 280 }}>
              {children}
            </Content>
          </LayoutAntd>
        </Content>
      </LayoutAntd>
      {loading && <Loading />}
    </>
  );
};
