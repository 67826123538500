// import "normalize.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MainProvider } from "./contexts/main";
import "./global.css";
import "./index.scss";
import { ThemeProvider } from "./theme";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <MainProvider>
    <ThemeProvider>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </ThemeProvider>
  </MainProvider>
);
