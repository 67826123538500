import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { EventGroupPrintResponse } from "../../../modules/home/pages/home/Home.functions";

const styles = StyleSheet.create({
  container: {
    width: "95mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "0.3mm solid black",
    borderBottom: "0.3mm solid black",
    marginBottom: "1.5mm"
  },
  containerTitle: {
    width: "95mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5mm",
    borderTop: "0.3mm solid black",
    paddingVertical: "0.8mm",
  },
  containerSubTitle: {
    width: "100%",
    fontSize: "2.8mm",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "0.5mm",
    borderBottom: "0.3mm solid black",
    paddingVertical: "0.8mm",
  },
  title: {
    width: "100%",
    fontSize: "3.5mm",
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
  },
  administration: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  name: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  date: {
    textAlign: "center",
    textTransform: "uppercase",
  },
});

interface HeaderProps {
  print?: EventGroupPrintResponse;
}

const Header: FC<HeaderProps> = ({ print }) => (
  <View style={styles.container}>
    <View style={styles.containerTitle}>
      <Text style={styles.title}>Lista de batismo e diversos</Text>
    </View>
    <View style={styles.containerSubTitle}>
      <Text style={styles.administration}>
        {print?.administration ?? "Belo Horizonte"}
      </Text>
      <Text style={styles.name}>{print?.name}</Text>
      <Text style={styles.date}>
        {print?.date && new Date(print?.date).toLocaleDateString()}
      </Text>
    </View>
  </View>
);

export default Header;
