import { StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2.25mm",
    fontWeight: 700,
    height: 12,
    borderBottom: "0.3mm solid black",
  },
  date: {
    width: "8%",
    textAlign: "left",
    textTransform: "uppercase",
  },
  hour: {
    width: "8%",
    textAlign: "left",
    textTransform: "uppercase",
  },
  local: {
    width: "44%",
    textAlign: "left",
    textTransform: "uppercase",
  },
  people: {
    width: "40%",
    textAlign: "left",
    textTransform: "uppercase",
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}>Data</Text>
    <Text style={styles.hour}>Hora</Text>
    <Text style={styles.local}>Localidade</Text>
    <Text style={styles.people}>Ancião</Text>
  </View>
);

export default TableHeader;
