import { Col, Layout as LayoutAntd, MenuProps, Row, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../models/appRoute.model";
import style from "./Header.module.scss";
const { Header: HeaderAntd } = LayoutAntd;

type MenuItem = Required<MenuProps>["items"][number];

interface HeaderProps {
  routes: AppRoute[];
}

export const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const { routes } = props;

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function getItem(
    label: React.ReactNode,
    onClick: any,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = routes?.map((item, index) => {
    return getItem(
      item.name,
      () => {
        navigate(item?.path!);
      },
      index + 1,
      item.icon
    );
  });

  return (
    <HeaderAntd
      style={{ background: colorBgContainer }}
      className={style.header}>
      <Col span={24}>
        <Row justify={"space-between"}>
          <Col>
            <img className={style.image} src="/images/logo-ccb-light.png" />
          </Col>
          {/* <Col>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["1"]}
              items={items}
              style={{ background: colorBgContainer }}
            />
          </Col> */}
        </Row>
      </Col>
    </HeaderAntd>
  );
};
