import {
  Document,
  Font,
  Page,
  StyleSheet,
  usePDF,
  View,
} from "@react-pdf/renderer";
import { Col, Row, Tabs, Typography } from "antd";
import { useEffect } from "react";
import { Html } from "react-pdf-html";
import ItemsTypes from "../../../../components/reports/table/ItemsTypes";
import { HomeFunctions } from "./Home.functions";

import { Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/fonts/Roboto/Roboto-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Roboto/Roboto-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontWeight: 400,
    padding: "10mm",
    paddingLeft: "7.5mm",
    flexDirection: "column",
  },
  warning: {
    fontSize: "3mm",
  },
});

export const Home = () => {
  const { printItem, setPrintItem, groups, refetch, onCompleted, loading } =
    HomeFunctions();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const MyDocument = () => (
    <Document
      title={`Lista de batismos e diversos - ${printItem && printItem.name}`}
      pageMode="fullScreen"
      author="contato@ccbbh.com.br"
      creationDate={new Date()}
      creator="CCB - BH"
      language="pt-BR">
      {printItem && (
        <>
          <Page size="A4" style={styles.page}>
            {ItemsTypes({
              print: printItem,
            })}
          </Page>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                fontSize: "10px",
                width: "100%",
                height: "277mm",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}>
              <Html
                style={{
                  fontSize: "10px",
                  width: "95mm",
                  height: "277mm",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "0 10px",
                  alignContent: "space-between",
                }}>
                {(printItem?.warning ??
                  "Nenhum aviso cadastrado. <br /><br />") +
                  '<img src="/images/Listadigital.png" style="cursor: initial; background-repeat: no-repeat; background-size: contain; background-position: center center; width: 100px;">'}
              </Html>
            </View>
          </Page>
        </>
      )}
    </Document>
  );

  const handlerChange = (activeKey: string) => {
    setPrintItem(null);
    refetch({
      request: {
        eventGroupId: activeKey,
      },
    }).then((response) => {
      onCompleted(response);
    });
  };

  const [pdf, setPdf] = usePDF();

  useEffect(() => {
    MyDocument && setPdf(MyDocument());
  }, [printItem]);

  return (
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row gutter={[8, 8]} justify={"center"}>
                <Typography.Title level={1} style={{ margin: 0, padding: 0 }}>
                  Lista de batismos e diversos digital
                </Typography.Title>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]} justify={"center"}>
                <Typography.Text color="#a1a1a1e0">
                  Escolha a lista de batismo e diversos para visualização e
                  download.
                </Typography.Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {loading && (
            <Row gutter={[8, 8]} justify={"center"}>
              <Typography.Text>
                Carregando as listas da regional Belo Horizonte...
              </Typography.Text>
            </Row>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]} style={{ overflowY: "auto" }}>
            <Col span={24}>
              {!loading && groups && groups?.length > 0 && (
                <Tabs
                  tabPosition={window.innerWidth < 500 ? "top" : "left"}
                  defaultActiveKey={groups?.find((group) => group.actual)?.id}
                  onChange={handlerChange}
                  items={groups?.map((group) => {
                    const label = `${group.name}${
                      group.actual ? " - Atual" : ""
                    }`;
                    return {
                      key: group.id,
                      label,
                      children: (
                        <Col span={24}>
                          <Row justify={"center"}>
                            {!printItem && (
                              <Typography.Text>
                                Carregando a lista {label}...
                              </Typography.Text>
                            )}
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div
                                style={{
                                  width: "100%",
                                  height: "calc(100vh - 200px)",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}>
                                {pdf.url && (
                                  <Viewer
                                    fileUrl={pdf.url}
                                    plugins={[defaultLayoutPluginInstance]}
                                  />
                                )}
                              </div>
                            </Worker>
                          </Row>
                        </Col>
                      ),
                    };
                  })}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
