import { message } from "antd";
import React, { createContext, useContext, useState } from "react";
import { BreadcrumbItem } from "../components/breadcrumb";

export interface MainContextData {
  successMessage: (message: string) => void;
  errorMessage: (message: string) => void;
  infoMessage: (message: string) => void;
  warningMessage: (message: string) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  titlePage: string | null;
  setTitlePage: React.Dispatch<React.SetStateAction<string | null>>;
  handleToggleLoading(): void;
  breadcrumbsItens: BreadcrumbItem[];
  handleChangeBreadcumb(items: BreadcrumbItem[]): void;
}

const MainContext = createContext<MainContextData>({} as MainContextData);

interface MainProviderProps {
  children: React.ReactNode;
}

const MainProvider: React.FC<MainProviderProps> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [titlePage, setTitlePage] = useState<string | null>(null);
  const [breadcrumbsItens, setBreadcrumbsItens] = useState<BreadcrumbItem[]>([
    {
      title: "Home",
    },
  ]);

  const handleChangeBreadcumb = (items: BreadcrumbItem[]) => {
    if (items?.length > 0) {
      setBreadcrumbsItens(items);
    } else {
      setBreadcrumbsItens([
        {
          title: "Home",
        },
      ]);
    }
  };

  const handleToggleLoading = () => {
    setLoading(!loading);
  };

  const successMessage = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 3,
    });
  };

  const errorMessage = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 3,
    });
  };

  const infoMessage = (message: string) => {
    messageApi.open({
      type: "info",
      content: message,
      duration: 3,
    });
  };

  const warningMessage = (message: string) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  return (
    <MainContext.Provider
      value={{
        successMessage,
        errorMessage,
        infoMessage,
        warningMessage,
        loading,
        setLoading,
        titlePage,
        setTitlePage,
        handleToggleLoading,
        breadcrumbsItens,
        handleChangeBreadcumb,
      }}>
      {contextHolder}
      {children}
    </MainContext.Provider>
  );
};

function useMain() {
  const context = useContext(MainContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { MainContext, MainProvider, useMain };

