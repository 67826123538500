import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LocalStorageService } from "./localStorage.service";
import { EnvironmentService } from "./envoriment.service";

const getBaseUrl = (service: string) => {
  if (EnvironmentService.isProd)
    return `https://api.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isStg)
    return `https://api-stg.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isDev)
    return `https://api-dev.ccbbh.com.br/${service}/graphql`;
  return "https://localhost:5001/graphql";
};

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization:
        `Bearer ${LocalStorageService.getLocalStorage(
          LocalStorageService.localStorageToken
        )}` || null,
    },
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  const notAuthorized =
    graphQLErrors!.findIndex(
      (_) => _.extensions?.code === "AUTH_NOT_AUTHENTICATED"
    ) >= 0;
  if (notAuthorized) {
    window.location.href = `/logout`;
    return;
  }
});

const httpLinkAuth = createHttpLink({
  uri: getBaseUrl("auth"),
});

const clientAuth = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkAuth])),
  cache: new InMemoryCache(),
});

const httpLinkSystem = createHttpLink({
  uri: getBaseUrl("systems"),
});

const clientSystem = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkSystem])),
  cache: new InMemoryCache(),
});

const httpLinkPeople = createHttpLink({
  uri: getBaseUrl("peoples"),
});

const clientPeople = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkPeople])),
  cache: new InMemoryCache(),
});

const httpLinkLocal = createHttpLink({
  uri: getBaseUrl("locations"),
});

const clientLocal = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkLocal])),
  cache: new InMemoryCache(),
});

const httpLinkEvent = createHttpLink({
  uri: getBaseUrl("events"),
});

const clientEvent = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkEvent])),
  cache: new InMemoryCache(),
});

const httpLinkBlog = createHttpLink({
  uri: getBaseUrl("blogs"),
});

const clientBlog = new ApolloClient({
  link: errorLink.concat(from([authMiddleware, httpLinkBlog])),
  cache: new InMemoryCache(),
});

export { clientAuth, clientSystem, clientPeople, clientLocal, clientEvent, clientBlog };
