import { Breadcrumb as BreadcrumbAntd } from "antd";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

interface BreadcrumbProps extends React.HTMLProps<HTMLDivElement> {
  items: BreadcrumbItem[];
}

interface BreadcrumbItem {
  title: string;
  path?: string;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement, MouseEvent>
  ) => {};
  id?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const { items, className } = props;
  const _props = {
    ...props,
    className: classNames(styles["container"], className),
  } as any;

  delete _props.items;

  return (
    <BreadcrumbAntd {..._props}>
      {items.map((item, index) => (
        <BreadcrumbAntd.Item
          key={index}
          className={classNames({
            [styles["actual"]]: index === items.length - 1,
            [styles["color"]]: true,
          })}>
          {item.onClick ? (
            <a
              onClick={(e) => {
                e.preventDefault();
                if (item.onClick) item.onClick(e);
              }}>
              {item.title}
            </a>
          ) : (
            <Link to={item.path ?? ""}>{item.title}</Link>
          )}
        </BreadcrumbAntd.Item>
      ))}
    </BreadcrumbAntd>
  );
};

export { Breadcrumb };
export type { BreadcrumbItem };

